<template>
  <v-btn
    :small="small"
    :x-small="xSmall"
    icon
    :value="computedValue"
    :color="color"
    @click.stop="toggle"
  >
    <v-icon
      data-cy="checkbox"
      :size="20"
    >{{icon(computedValue)}}</v-icon>
  </v-btn>
</template>
<script>
export default {
  computed: {
    computedValue: {
      get () { return this.innerValue },
      set (v) {
        if (!this.lodash.isEqual(this.innerValue, v)) {
          this.innerValue = v
          this.dataDocumentEmit()
        }
      }
    }
  },
  data () {
    return {
      toggleValue: null,
      innerValue: NaN,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.value, this.innerValue)) {
        this.$emit('update:value', { value: this.innerValue, key: this.toggleValue })
      }
    },
    icon (e) {
      switch (e) {
        case 1:
          return this.$icon('i.CheckboxPlus')
        case 0:
          return this.$icon('i.CheckboxMinus')
        default:
          return this.$icon('i.CheckboxBlank')
      }
    },
    click (e) {
      this.toggle(e)
    },
    toggle (e) {
      if (!this.disabled) {
        this.toggleValue = e
        switch (this.computedValue) {
          case 1:
            this.computedValue = e.shiftKey ? 0 : NaN
            break
          case 0:
            this.computedValue = e.ctrlKey ? 1 : NaN
            break
          default:
            this.computedValue = e.shiftKey ? 0 : 1
            break
        }
      }
    }
  },
  props: {
    color: {
      default: 'primary',
      type: String
    },
    disabled: Boolean,
    value: Number,
    small: Boolean,
    xSmall: Boolean
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.computedValue = v
      }
    }
  }
}
</script>
